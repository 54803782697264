import React from 'react';
import {Link} from "react-router-dom";

const SidebarBanner = () => {
    return (
        <div className="sidebar-widget-content">
            <div className="sidebar-widget-banner overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/breadcrumb-bg.jpg)`}} >
            <h3 className="title">Abonnez-vous à notre newsletter</h3>
            <p>Recevez les dernières nouvelles sur la gestion de la paie et des ressources humaines, ainsi que des mises à jour importantes sur nos services et conseils d'experts.</p>
            <Link to={process.env.PUBLIC_URL + "/"}>S'abonner maintenant</Link>
        </div>

        </div>
    )
}

export default SidebarBanner;
