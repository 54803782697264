import React from 'react';

const GoogleMap = () => {
  return (<div className="google-map-area section text-center section-padding-bottom">
      <div className="container">
        <div className="contact-map-area" data-aos="fade-up">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.845112028316!2d2.3474636769731028!3d48.86116387133256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1ead31d427%3A0x378cd846d4297bf7!2s32%20Bd%20de%20S%C3%A9bastopol%2C%2075004%20Paris%2C%20France!5e0!3m2!1sen!2sca!4v1720517906237!5m2!1sen!2sca"
            aria-hidden="false"
            title="Localisation de Alvine Conseils"
          ></iframe>
        </div>
      </div>
    </div>);
};

export default GoogleMap;