import React from 'react';
import { NavLink } from 'react-router-dom';
import { getClosest, getSiblings, slideToggle, slideUp } from '../../../utils';

const MobileNavMenu = () => {
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (parentEl?.classList.contains('menu-toggle') || target.classList.contains('menu-toggle')) {
      const element = target.classList.contains('icon') ? parentEl : target;
      const parent = getClosest(element, 'li');
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === 'UL') {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === 'UL') {
          slideToggle(child, 1000);
        }
      });
    }
  };
  return (<nav className="site-mobile-menu">
    <ul>
      <li>
        <NavLink to={process.env.PUBLIC_URL + '/'}><span className="menu-text">Accueil</span></NavLink>
      </li>
      <li>
        <NavLink to={process.env.PUBLIC_URL + '/a-propos'}><span className="menu-text">À propos</span></NavLink>
      </li>
      <li className="has-children">
        <NavLink to={process.env.PUBLIC_URL + '/services'}><span className="menu-text">Services</span></NavLink>
        <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
        <ul className="sub-menu">
          <li><NavLink to={process.env.PUBLIC_URL + '/services/externalisation-paie'}><span className="menu-text">Externalisation de la Paie</span></NavLink></li>
          <li><NavLink to={process.env.PUBLIC_URL + '/services/integration-silae'}><span className="menu-text">Integration de SILAE</span></NavLink></li>
          <li><NavLink to={process.env.PUBLIC_URL + '/services/formations-silae'}><span className="menu-text">Formations SILAE</span></NavLink></li>
        </ul>
      </li>
      <li>
        <NavLink to={process.env.PUBLIC_URL + '/blog'}><span className="menu-text">Blog</span></NavLink>
      </li>
      <li>
        <NavLink to={process.env.PUBLIC_URL + '/contact'}><span className="menu-text">Contact</span></NavLink>
      </li>
    </ul>

  </nav>);
};

export default MobileNavMenu;
